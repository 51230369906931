@media (min-width: 0px) {
  /* grid items */
  .item-sidebar {
    grid-area: sidebar;
    grid-column-start: 1;
    grid-column-end: 37;
    grid-row-start: 1;
    grid-row-end: 10;
    background-color: #012E40;
    color: white;
  }
  .collapsed .item-sidebar {
    grid-area: sidebar;
    grid-column-start: 1;
    grid-column-end: 37;
    grid-row-start: 1;
    grid-row-end: 3;
    background-color: #012E40;
    color: white;
  }
  .item-hamburger {
    grid-area: hamburger;
    grid-column-start: 29;
    grid-column-end: 34;
    grid-row-start: 1;
    grid-row-end: 3;
    background-color: #012E40;
    color: white;
    transition: .5s;
    margin-top:  26px;
  }
  .item-main {
    grid-area: main;
    grid-column-start: 1;
    grid-column-end: 37;
    grid-row-start: 10;
    grid-row-end: 19;
    /* background-color:  seagreen; */
  }

  .item-main-content {
    grid-area: mainContent;
    grid-column-start: 1;
    grid-column-end: 37;
    grid-row-start: 10;
    grid-row-end: 19;
    height: 100%;
    font-size: 1rem;
    overflow-y: scroll;
    scroll-behavior: smooth;
  }
 .collapsed .item-main-content {
    grid-area: mainContent;
    grid-column-start: 1;
    grid-column-end: 37;
    grid-row-start: 3;
    grid-row-end: 19;
    height: 100%;
    font-size: 1rem;
    overflow-y: scroll;
    scroll-behavior: smooth;
  }
  .main-content,
  .item-main-content,
  .sidebar,
  .item-sidebar-right {
    transition: 1s;
  }
  /* Hide default HTML checkbox */
  .item-switch input,
  .item-hamburger-label input {
    opacity: 0;
    width: 0;
    height: 0;
  }
.item-logo {
    grid-area: logo;
    grid-column-start: 4;
    grid-column-end: 5;
    grid-row-start: 1;
    grid-row-end: 3;
    justify-self: end;
    margin-top: 28px;
  }

  .item-menu.collapsed {
    display: none;
  }

  .item-menu {
    grid-area: menu;
    grid-column-start: 1;
    grid-column-end: 34;
    grid-row-start: 3;
    grid-row-end: 4;
    align-self: center;
    justify-self: end;
    text-align: right;
    padding-top:  20px;
  }

  .item-switch,
  .item-hamburger-label {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    grid-area: darkMode;
    grid-column-start: 15;
    grid-column-end: 34;
    grid-row-start: 5;
    grid-row-end: 8;
    justify-self: end;
    color: white;
  }
  .item-contact {
    grid-area: contact;
    grid-column-start: 25;
    grid-column-end: 35;
    grid-row-start: 5;
    grid-row-end: 11;
    align-self: center;
    justify-self: end;
    text-align: right;
  }

  .item-contact>a {
    text-decoration: none;
    color: white;
  }

  .item-dark-mode-label {
    grid-area: darkModeLabel;
    grid-column-start: 16;
    grid-column-end: 26;
    grid-row-start: 5;
    grid-row-end: 8;
    justify-self: end;
    color: white;
    text-align: right;
  }
  /* end grid items */
  /* global items */
  .App {
    font-family: 'Sen', sans-serif;
    height: 100vh;
    display: grid;
    grid-template-columns: repeat(36, 1fr);
    grid-template-rows: repeat(18, 1fr);
  }

  .dark-mode {
    color: white;
    background-color: #012E40;
    transition: .5s;
  }

  .light-mode {
    background-color: #f2ece7;
    transition: .5s;
  }

  .dark-mode a {
    color: #f26c00;
  }

  /* end global items */

  .submenu-item {
    display: none;
    transition: .3s;
  }

  html {
    background-color: #012E40;
  }

  .App>div {
    transition: .3s;
  }
  .hidden-mobile {
    display: none;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 500;
  }

  .App,
  .page-grid,
    {
    font-family: 'Sen', sans-serif;
    height: 100vh;
    display: grid;
    grid-template-columns: repeat(36, 1fr);
    grid-template-rows: repeat(18, 1fr);
    overflow-y: hidden;
  }




  .content-collapsed {
    grid-row-start: 3;
    transition: .5s;
  }

  .content-expanded {
    transition: .5s;
  }

  .dark-mode {
    color: white;
    background-color: #012E40;
    transition: .5s;
  }


  .main-content {
    padding: 20px 30px;
  }

  footer.App-footer {
    background-color: #012E40;
    min-height: 100px;
    color: white;
  }

  .footer-content {

    padding: 40px 50px;
  }

  li {
    margin-bottom: 0.4rem;
  }

  

  .hide {
    display: none;
  }



  .item-dark-mode-label>span {
    line-height: 34px;
  }

  .menu-item>a {
    padding: 6px 0px 6px 16px;
    text-decoration: none;
    font-size: 1.5rem;
    color: #F2E3D5;
    display: block;
    text-align: right;
  }

  .submenu-item>a {
    padding: 6px 0px 6px 16px;
    text-decoration: none;
    font-size: 1.1rem;
    color: #F2E3D5;
    display: block;
    text-align: right;
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }

  .dark-mode span.slider.round {
    background-color: #f26c00;
  }

  input:focus {
    box-shadow: 0 0 1px #2196F3;
  }

  input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }

  .job-content {
    margin: 0;
    font-style: italic;
  }

  /* hide sidebar on mobile */
  .item-sidebar-right {
    display: none;
  }
  .collapsed .item-contact {
    display: none;
    transition: .5s;
  }
  .collapsed .item-dark-mode-label, .collapsed .item-switch {
    display: none;
    transition: .5s;
  }
  .expanded .item-dark-mode-label, .expanded .item-switch {
    display: block;
    transition: .5s;
  }
  .mobile-about-content {
    padding:  20px;
    max-width: 100%;
  }
  .mobile-about-content img {
    max-width:  100%;
  }
  img.banner-img {
    max-width: 100%;
  }
}


@media (min-width: 600px) {
  .hidden-mobile {
    display: block;
  }
  .item-menu.collapsed {
    display: block;
  }
  .collapsed .item-contact {
    display: block;
    transition: .5s;
  }
  .collapsed .item-dark-mode-label, .collapsed .item-switch {
    display: block;
    transition: .5s;
  }
  .main-content {
    max-width: 800px;
    padding: 15px 30px 100px 30px;
  }

  .mobile-about-content {
    display: none;
  }
  .item-contact {
    display: block;
  }
  .item-hamburger {
    display: none;
  }
  .item-sidebar, .collapsed .item-sidebar {
    grid-area: sidebar;
    grid-column: 1 / 11;
    grid-row: 1 / 19;
    background-color: #012E40;
    color: white;
    padding: 60px 40px;
  }
  .item-main {
    grid-area: main;
    grid-column-start: 11;
    grid-column-end: 37;
    grid-row-start: 1;
    grid-row-end: 19;
    /* background-color:  seagreen; */
  }
  .item-main-content, .collapsed .item-main-content {
    grid-area: mainContent;
    grid-column-start: 11;
    grid-column-end: 37;
    grid-row-start: 1;
    grid-row-end: 19;
    height: 100%;
    font-size: 1.2rem;
    overflow-y: scroll;
    scroll-behavior: smooth;
  }

  .main-content {
    max-width: 800px;
    padding: 15px 50px 100px 50px;
  }

  footer.App-footer {
    background-color: #012E40;
    min-height: 100px;
    color: white;
  }

  .footer-content {

    padding: 40px 50px;
  }

  .item-logo {
    grid-area: logo;
    grid-column-start: 1;
    grid-column-end: 10;
    grid-row-start: 2;
    grid-row-end: 3;
    justify-self: end;
  }

  .item-menu {
    grid-area: menu;
    grid-column-start: 1;
    grid-column-end: 10;
    grid-row-start: 4;
    grid-row-end: 5;
    align-self: center;
    justify-self: end;
    text-align: right;
  }

  .item-contact {
    grid-area: contact;
    grid-column-start: 2;
    grid-column-end: 10;
    grid-row-start: 14;
    grid-row-end: 15;
    align-self: center;
    justify-self: end;
    text-align: right;
  }

  .item-contact>a {
    text-decoration: none;
    color: white;
  }

  .item-dark-mode-label {
    grid-area: darkModeLabel;
    grid-column-start: 1;
    grid-column-end: 7;
    grid-row-start: 17;
    grid-row-end: 19;
    justify-self: end;
    color: white;
    text-align: right;
  }

  .item-dark-mode-label>span {
    line-height: 32px;
  }

  .menu-item>a {
    padding: 6px 0px 6px 16px;
    text-decoration: none;
    font-size: 1.5rem;
    color: #F2E3D5;
    display: block;
    text-align: right;
  }

  .submenu-item>a {
    padding: 6px 0px 6px 16px;
    text-decoration: none;
    font-size: 1.1rem;
    color: #F2E3D5;
    display: block;
    text-align: right;
  }

  .item-switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    grid-area: darkMode;
    grid-column-start: 8;
    grid-column-end: 10;
    grid-row-start: 17;
    grid-row-end: 18;
    justify-self: end;
    color: white;
  }

  /* Hide default HTML checkbox */
  .item-switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  /* hide sidebar on mobile */ 
    .item-sidebar-right { 
      display:  none;
    }
}

@media (min-width: 900px) {
   .item-sidebar, .collapsed .item-sidebar {
    grid-area: sidebar;
    grid-column-start: 1;
    grid-column-end: 8;
    grid-row-start: 1;
    grid-row-end: 19;
    background-color: #012E40;
    color: white;
    padding: 60px 40px;
  }
    .item-sidebar-right {
      display: block;
    }
  .item-main {
    grid-area: main;
    grid-column-start: 8;
    grid-column-end: 37;
    grid-row-start: 1;
    grid-row-end: 19;
    /* background-color:  seagreen; */
  }
  .item-main, .item-spiral {
    grid-area: main;
    grid-column-start: 8;
    grid-column-end: 37;
    grid-row-start: 1;
    grid-row-end: 19;
    /* background-color:  seagreen; */
  }
  .item-sidebar-right {
    grid-area: skills;
    grid-column-start: 28;
    grid-column-end: 37;
    grid-row-start: 1;
    grid-row-end: 19;
    border-left:  1px solid rgba(0,0,0,.2);
    padding: 20px;
  }
  .item-sidebar-right .skills li, .programming-languages li, .tools li {
    list-style: none;
    border: 1px solid black;
    border-radius: 10px;
    padding: 8px;
    display: inline-block;
    margin: 2px;
  }
   .item-sidebar-right .skills {
    clear:  left;
    padding-top:  6px;
   }
  #skills-interests {
    /* in this media query the skills and intersts are currently shown in the right sidebar */
    display:  none;
  }
   .item-main-content, .collapsed .item-main-content, .item-project-content{
    grid-area: mainContent;
    grid-column-start: 8;
    grid-column-end: 28;
    grid-row-start: 1;
    grid-row-end: 19;
    height: 100%;
    font-size: 1.2rem;
    overflow-y: scroll;
    scroll-behavior: smooth;
  }
 .item-spiral-content {
    grid-area: mainContent;
    grid-column-start: 8;
    grid-column-end: 37;
    grid-row-start: 1;
    grid-row-end: 19;
    font-size: 1.2rem;
    overflow-y: scroll;
    scroll-behavior: smooth;
    background-color:  blueviolet;

 }
  .spiral-content {
    min-height:  calc(100% - 100px);
  }
  .main-content {
    padding-top:  80px;
  }
  .summary-label {
    border: 1px solid black;
    background-color: #f26c00;
    position: relative;
    left: -2px;
    bottom: 2px;
    padding: 5px;
    color: white;
    font-weight: 600;
    margin: 5px;
    display: inline-block;
    line-height: 24px;
    float:  left;
    width:  40%;
  }
  .summary-text {
    font-weight:  400;
    font-size:  1rem;
    line-height:  1.2rem;
    display:  inline-block;
  }
  .dark-mode {
    color: white;
    background-color: #012E40;
    transition: .5s;
    border-color: white !important;
  }
  .dark-mode li {
      border-color:  white !important;
  }
  .light-mode {
    background-color: #f2ece7;
    transition: .5s;
  }
  .light-mode li {
      border-color: black;
  }
  .sidebar-dark-mode {
    color: white;
    background-color: #012E40;
    transition: .5s;
    border-color: white !important;
  }
  .sidebar-dark-mode li {
      border-color:  white !important;
  }
  .sidebar-light-mode {
    background-color: #f2e7dd;
    transition: .5s;
  }
  .sidebar-light-mode li {
      border-color: black;
  }
  .main-content {
    max-width: 800px;
    padding: 0px 50px 100px 50px;
  }
  img.km-wordmark {
    margin-top: 36px;
  }
  footer.App-footer {
    background-color: #012E40;
    min-height: 100px;
    color: white;

    /* this should be the same as the padding on .item-main-content */
    margin-left:  -40px;
  }

  .footer-content {

    padding: 40px 90px;
  }

  .item-logo {
    grid-area: logo;
    grid-column-start: 1;
    grid-column-end: 7;
    grid-row-start: 1;
    grid-row-end: 2;
    justify-self: end;
  }

  .item-menu {
    grid-area: menu;
    grid-column-start: 1;
    grid-column-end: 7;
    grid-row-start: 4;
    grid-row-end: 5;
    align-self: center;
    justify-self: end;
    text-align: right;
  }

  .item-contact {
    grid-area: contact;
    grid-column-start: 2;
    grid-column-end: 7;
    grid-row-start: 15;
    grid-row-end: 16;
    align-self: center;
    justify-self: end;
    text-align: right;
  }

  .item-contact>a {
    text-decoration: none;
    color: white;
  }

  .item-dark-mode-label {
    grid-area: darkModeLabel;
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 17;
    grid-row-end: 19;
    justify-self: end;
    color: white;
    text-align: right;
  }

  .item-dark-mode-label>span {
    line-height: 32px;
  }

  .menu-item>a {
    padding: 6px 0px 6px 16px;
    text-decoration: none;
    font-size: 1.8rem;
    color: #F2E3D5;
    display: block;
    text-align: right;
  }

  .submenu-item > a {
    padding: 6px 0px 6px 16px;
    text-decoration: none;
    font-size: 1.3rem;
    color: #F2E3D5;
    display: block;
    text-align: right;
  }
  .item-switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    grid-area: darkMode;
    grid-column-start: 5;
    grid-column-end: 7;
    grid-row-start: 17;
    grid-row-end: 18;
    justify-self: end;
    color: white;
  }
  #skills-interests-menu-item {
    /* hide this on extra large screens because already in the sidebar */
    display: none;
  }
  .heading-skills-interests {

  }
  /* Hide default HTML checkbox */
  .item-switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  .resume-page .submenu-item {
    display:  block;
  }

/* this area is a dynamic sidebar */

  .sidebar-home-content, .sidebar-projects-content, .sidebar-resume-content {
    display:  none;
  }

  .home-page .sidebar-home-content, .projects-page .sidebar-projects-content, .resume-page .sidebar-resume-content {
    display:  block;
  }

/* end CSS dynamic sidebar */


  .resume-page .sidebar-

  .item-sidebar-right ul {
    padding: 0;
  }
  #education {
    scroll-margin-top: -200px;
  }
  .banner-img {
    width:  100%;
    background-size:  cover;
    background-position:  center center;
  }
  .main-content h1 {
    margin-top: 33px;
  }
  :root {
    --base:              #f26c00;
    --bg-blend:          multiply;
    --blur:              0px;
    --fg-blend:          lighten;
    --fg-blend-dark:     overlay;
    --foreground:        #012e40;
    --opacity:           1;
  }
  .dark-mode .img-wrapper,  .sidebar-dark-mode .img-wrapper {
    background-color:    var(--base);
  }
  .dark-mode .img-wrapper img, .dark-mode .img-wrapper > div, .sidebar-dark-mode .img-wrapper img {
    filter:              grayscale(100%) contrast(1) blur(var(--blur));
    mix-blend-mode:      var(--bg-blend);
    object-fit:          cover;
    opacity:             var(--opacity);
  }
  .dark-mode .img-wrapper::before, .sidebar-dark-mode .img-wrapper::before {
    background-color:    var(--foreground);
    mix-blend-mode:      var(--fg-blend);
  }
  .profile-img {
    max-width:  100%;
  }
  .main-content .skills, .main-content .programming-languages, .main-content .tools {
    display: none;
  }
  .item-main-content {
    position:  relative;
  }
  .item-footer-content {
    width: 100%;
    bottom: 0;
  }
  html, body {
  height: 100%;
  }
  .item-main-content {
    display: flex;
    flex-direction: column;
  }
  .content, .item-footer {
    flex-grow: 1;
  }
    html, body, .spiral-content {height:100%;}

  
  .item {
    display: grid;
    grid-template-columns: subgrid;
    grid-template-rows: subgrid;
  }
  .spi-one {
    grid-column: 1 / 22;
    grid-row: 1 / 18;
    background-color: slateblue;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    cursor:  pointer;
  }
  .spi-two {
    grid-column: 22 / 36;
    grid-row: 1 / 11;
    background-color: darkslateblue;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }

  .spi-three {
    grid-column: 27 / 36;
    grid-row: 11 / 18;
    background-color: mediumpurple;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }
  .spi-four {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    grid-column: 22 / 27;
    grid-row: 14 / 18;
    background-color: green;
  }
  .spi-five {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    grid-column: 22 / 25;
    grid-row: 11 / 14;
    background-color: purple;
  }
  .spi-six {
    grid-column: 25 / 27;
    grid-row: 11 / 14;
    background-color: rebeccapurple;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }
  .spiral-content > div {
        transition: .5s;
  }
}
@media (min-width: 1400px) {
 .item-sidebar, .collapsed .item-sidebar  {
    grid-area: sidebar;
    grid-column-start: 1;
    grid-column-end: 8;
    grid-row-start: 1;
    grid-row-end: 19;
    background-color: #012E40;
    color: white;
    padding: 60px 40px;
  }

  .item-main {
    grid-area: main;
    grid-column-start: 8;
    grid-column-end: 37;
    grid-row-start: 1;
    grid-row-end: 19;
    /* background-color:  seagreen; */
  }
  .item-main, .item-spiral {
    grid-area: main;
    grid-column-start: 8;
    grid-column-end: 37;
    grid-row-start: 1;
    grid-row-end: 19;
    /* background-color:  seagreen; */
  }
  .item-sidebar-right {
    grid-area: skills;
    grid-column-start: 28;
    grid-column-end: 37;
    grid-row-start: 1;
    grid-row-end: 19;
    border-left:  1px solid rgba(0,0,0,.2);
    padding: 20px;
  }
  .item-sidebar-right .skills li, .programming-languages li, .tools li {
    list-style: none;
    border: 1px solid black;
    border-radius: 10px;
    padding: 8px;
    display: inline-block;
    margin: 2px;
  }
   .item-sidebar-right .skills {
    clear:  left;
    padding-top:  6px;
   }
  #skills-interests {
    /* in this media query the skills and intersts are currently shown in the right sidebar */
    display:  none;
  }
   .item-main-content, .collapsed .item-main-content, .item-project-content{
    grid-area: mainContent;
    grid-column-start: 8;
    grid-column-end: 28;
    grid-row-start: 1;
    grid-row-end: 19;
    height: 100%;
    font-size: 1.2rem;
    overflow-y: scroll;
    scroll-behavior: smooth;
  }
 .item-spiral-content {
    grid-area: mainContent;
    grid-column-start: 8;
    grid-column-end: 37;
    grid-row-start: 1;
    grid-row-end: 19;
    font-size: 1.2rem;
    overflow-y: scroll;
    scroll-behavior: smooth;
    background-color:  blueviolet;

 }
  .spiral-content {
    min-height:  calc(100% - 100px);
  }
  .main-content {
    padding-top:  80px;
  }
  .summary-label {
    border: 1px solid black;
    background-color: #f26c00;
    position: relative;
    left: -2px;
    bottom: 2px;
    padding: 5px;
    color: white;
    font-weight: 600;
    margin: 5px;
    display: inline-block;
    line-height: 24px;
    float:  left;
    width:  40%;
  }
  .summary-text {
    font-weight:  400;
    font-size:  1rem;
    line-height:  1.2rem;
    display:  inline-block;
  }
  .dark-mode {
    color: white;
    background-color: #012E40;
    transition: .5s;
    border-color: white !important;
  }
  .dark-mode li {
      border-color:  white !important;
  }
  .light-mode {
    background-color: #f2ece7;
    transition: .5s;
  }
  .light-mode li {
      border-color: black;
  }
  .sidebar-dark-mode {
    color: white;
    background-color: #012E40;
    transition: .5s;
    border-color: white !important;
  }
  .sidebar-dark-mode li {
      border-color:  white !important;
  }
  .sidebar-light-mode {
    background-color: #f2e7dd;
    transition: .5s;
  }
  .sidebar-light-mode li {
      border-color: black;
  }
  .main-content {
    max-width: 800px;
    padding: 0px 50px 100px 50px;
  }
  img.km-wordmark {
    margin-top: 36px;
  }
  footer.App-footer {
    background-color: #012E40;
    min-height: 100px;
    color: white;

    /* this should be the same as the padding on .item-main-content */
    margin-left:  -40px;
  }

  .footer-content {

    padding: 40px 90px;
  }

  .item-logo {
    grid-area: logo;
    grid-column-start: 1;
    grid-column-end: 7;
    grid-row-start: 1;
    grid-row-end: 2;
    justify-self: end;
  }

  .item-menu {
    grid-area: menu;
    grid-column-start: 1;
    grid-column-end: 7;
    grid-row-start: 4;
    grid-row-end: 5;
    align-self: center;
    justify-self: end;
    text-align: right;
  }

  .item-contact {
    grid-area: contact;
    grid-column-start: 2;
    grid-column-end: 7;
    grid-row-start: 15;
    grid-row-end: 16;
    align-self: center;
    justify-self: end;
    text-align: right;
  }

  .item-contact>a {
    text-decoration: none;
    color: white;
  }

  .item-dark-mode-label {
    grid-area: darkModeLabel;
    grid-column-start: 3;
    grid-column-end: 5;
    grid-row-start: 17;
    grid-row-end: 19;
    justify-self: end;
    color: white;
    text-align: right;
  }

  .item-dark-mode-label>span {
    line-height: 32px;
  }

  .menu-item>a {
    padding: 6px 0px 6px 16px;
    text-decoration: none;
    font-size: 1.8rem;
    color: #F2E3D5;
    display: block;
    text-align: right;
  }

  .submenu-item > a {
    padding: 6px 0px 6px 16px;
    text-decoration: none;
    font-size: 1.3rem;
    color: #F2E3D5;
    display: block;
    text-align: right;
  }
  .item-switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    grid-area: darkMode;
    grid-column-start: 5;
    grid-column-end: 7;
    grid-row-start: 17;
    grid-row-end: 18;
    justify-self: end;
    color: white;
  }
  #skills-interests-menu-item {
    /* hide this on extra large screens because already in the sidebar */
    display: none;
  }
  .heading-skills-interests {

  }
  /* Hide default HTML checkbox */
  .item-switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  .submenu-item {
    display:  none;
    transition: .3s;
  }
  .resume-page .submenu-item {
    display:  block;
  }

/* this area is a dynamic sidebar */

  .sidebar-home-content, .sidebar-projects-content, .sidebar-resume-content {
    display:  none;
  }

  .home-page .sidebar-home-content, .projects-page .sidebar-projects-content, .resume-page .sidebar-resume-content {
    display:  block;
  }

/* end CSS dynamic sidebar */


  .resume-page .sidebar-

  .item-sidebar-right ul {
    padding: 0;
  }
  #education {
    scroll-margin-top: -200px;
  }
  .banner-img {
    width:  100%;
    background-size:  cover;
    background-position:  center center;
  }
  .main-content h1 {
    margin-top: 33px;
  }
  :root {
    --base:              #f26c00;
    --bg-blend:          multiply;
    --blur:              0px;
    --fg-blend:          lighten;
    --fg-blend-dark:     overlay;
    --foreground:        #012e40;
    --opacity:           1;
  }
  .dark-mode .img-wrapper,  .sidebar-dark-mode .img-wrapper {
    background-color:    var(--base);
  }
  .dark-mode .img-wrapper img, .dark-mode .img-wrapper > div, .sidebar-dark-mode .img-wrapper img {
    filter:              grayscale(100%) contrast(1) blur(var(--blur));
    mix-blend-mode:      var(--bg-blend);
    object-fit:          cover;
    opacity:             var(--opacity);
  }
  .dark-mode .img-wrapper::before, .sidebar-dark-mode .img-wrapper::before {
    background-color:    var(--foreground);
    mix-blend-mode:      var(--fg-blend);
  }
  .profile-img {
    max-width:  100%;
  }
  .main-content .skills, .main-content .programming-languages, .main-content .tools {
    display: none;
  }
  .item-main-content {
    position:  relative;
  }
  .item-footer-content {
    width: 100%;
    bottom: 0;
  }
  html, body {
  height: 100%;
  }
  .item-main-content {
    display: flex;
    flex-direction: column;
  }
  .content, .item-footer {
    flex-grow: 1;
  }
    html, body, .spiral-content {height:100%;}

  
  .item {
    display: grid;
    grid-template-columns: subgrid;
    grid-template-rows: subgrid;
  }
  .spi-one {
    grid-column: 1 / 22;
    grid-row: 1 / 18;
    background-color: slateblue;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    cursor:  pointer;
  }
  .spi-two {
    grid-column: 22 / 36;
    grid-row: 1 / 11;
    background-color: darkslateblue;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }

  .spi-three {
    grid-column: 27 / 36;
    grid-row: 11 / 18;
    background-color: mediumpurple;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }
  .spi-four {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    grid-column: 22 / 27;
    grid-row: 14 / 18;
    background-color: green;
  }
  .spi-five {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    grid-column: 22 / 25;
    grid-row: 11 / 14;
    background-color: purple;
  }
  .spi-six {
    grid-column: 25 / 27;
    grid-row: 11 / 14;
    background-color: rebeccapurple;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }
  .spiral-content > div {
        transition: .5s;
  }
}

@media (min-width: 1700px) {
  .item-sidebar, .collapsed .item-sidebar  {
    grid-area: sidebar;
    grid-column-start: 1;
    grid-column-end: 8;
    grid-row-start: 1;
    grid-row-end: 19;
    background-color: #012E40;
    color: white;
    padding: 60px 40px;
  }

  .item-main {
    grid-area: main;
    grid-column-start: 8;
    grid-column-end: 37;
    grid-row-start: 1;
    grid-row-end: 19;
    /* background-color:  seagreen; */
  }
  .item-main, .item-spiral {
    grid-area: main;
    grid-column-start: 8;
    grid-column-end: 37;
    grid-row-start: 1;
    grid-row-end: 19;
    /* background-color:  seagreen; */
  }
  .item-sidebar-right {
    grid-area: skills;
    grid-column-start: 29;
    grid-column-end: 37;
    grid-row-start: 1;
    grid-row-end: 19;
    border-left:  1px solid rgba(0,0,0,.2);
    padding: 40px;
  }
  .item-sidebar-right .skills li, .programming-languages li, .tools li {
    list-style: none;
    border: 1px solid black;
    border-radius: 10px;
    padding: 8px;
    display: inline-block;
    margin: 2px;
  }
   .item-sidebar-right .skills {
    clear:  left;
    padding-top:  6px;
   }
  #skills-interests {
    /* in this media query the skills and intersts are currently shown in the right sidebar */
    display:  none;
  }
   .item-main-content, .collapsed .item-main-content, .item-project-content{
    grid-area: mainContent;
    grid-column-start: 8;
    grid-column-end: 29;
    grid-row-start: 1;
    grid-row-end: 19;
    height: 100%;
    font-size: 1.2rem;
    overflow-y: scroll;
    scroll-behavior: smooth;
  }
 .item-spiral-content {
    grid-area: mainContent;
    grid-column-start: 8;
    grid-column-end: 37;
    grid-row-start: 1;
    grid-row-end: 19;
    font-size: 1.2rem;
    overflow-y: scroll;
    scroll-behavior: smooth;
    background-color:  blueviolet;

 }
  .spiral-content {
    min-height:  calc(100% - 100px);
  }
  .main-content {
    padding-top:  80px;
  }
  .summary-label {
    border: 1px solid black;
    background-color: #f26c00;
    position: relative;
    left: -2px;
    bottom: 2px;
    padding: 5px;
    color: white;
    font-weight: 600;
    margin: 5px;
    display: inline-block;
    line-height: 24px;
    float:  left;
    width:  40%;
  }
  .summary-text {
    font-weight:  400;
    font-size:  1rem;
    line-height:  1.2rem;
    display:  inline-block;
  }
  .dark-mode {
    color: white;
    background-color: #012E40;
    transition: .5s;
    border-color: white !important;
  }
  .dark-mode li {
      border-color:  white !important;
  }
  .light-mode {
    background-color: #f2ece7;
    transition: .5s;
  }
  .light-mode li {
      border-color: black;
  }
  .sidebar-dark-mode {
    color: white;
    background-color: #012E40;
    transition: .5s;
    border-color: white !important;
  }
  .sidebar-dark-mode li {
      border-color:  white !important;
  }
  .sidebar-light-mode {
    background-color: #f2e7dd;
    transition: .5s;
  }
  .sidebar-light-mode li {
      border-color: black;
  }
  .main-content {
    max-width: 800px;
    padding: 0px 50px 100px 50px;
  }
  img.km-wordmark {
    margin-top: 36px;
  }
  footer.App-footer {
    background-color: #012E40;
    min-height: 100px;
    color: white;

    /* this should be the same as the padding on .item-main-content */
    margin-left:  -40px;
  }

  .footer-content {

    padding: 40px 90px;
  }

  .item-logo {
    grid-area: logo;
    grid-column-start: 1;
    grid-column-end: 7;
    grid-row-start: 1;
    grid-row-end: 2;
    justify-self: end;
  }

  .item-menu {
    grid-area: menu;
    grid-column-start: 1;
    grid-column-end: 7;
    grid-row-start: 4;
    grid-row-end: 5;
    align-self: center;
    justify-self: end;
    text-align: right;
  }

  .item-contact {
    grid-area: contact;
    grid-column-start: 2;
    grid-column-end: 7;
    grid-row-start: 15;
    grid-row-end: 16;
    align-self: center;
    justify-self: end;
    text-align: right;
  }

  .item-contact>a {
    text-decoration: none;
    color: white;
  }

  .item-dark-mode-label {
    grid-area: darkModeLabel;
    grid-column-start: 3;
    grid-column-end: 5;
    grid-row-start: 17;
    grid-row-end: 19;
    justify-self: end;
    color: white;
    text-align: right;
  }

  .item-dark-mode-label>span {
    line-height: 32px;
  }

  .menu-item>a {
    padding: 6px 0px 6px 16px;
    text-decoration: none;
    font-size: 1.8rem;
    color: #F2E3D5;
    display: block;
    text-align: right;
  }

  .submenu-item > a {
    padding: 6px 0px 6px 16px;
    text-decoration: none;
    font-size: 1.3rem;
    color: #F2E3D5;
    display: block;
    text-align: right;
  }
  .item-switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    grid-area: darkMode;
    grid-column-start: 5;
    grid-column-end: 7;
    grid-row-start: 17;
    grid-row-end: 18;
    justify-self: end;
    color: white;
  }
  #skills-interests-menu-item {
    /* hide this on extra large screens because already in the sidebar */
    display: none;
  }
  .heading-skills-interests {

  }
  /* Hide default HTML checkbox */
  .item-switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  .submenu-item {
    display:  none;
    transition: .3s;
  }
  .resume-page .submenu-item {
    display:  block;
  }

/* this area is a dynamic sidebar */

  .sidebar-home-content, .sidebar-projects-content, .sidebar-resume-content {
    display:  none;
  }

  .home-page .sidebar-home-content, .projects-page .sidebar-projects-content, .resume-page .sidebar-resume-content {
    display:  block;
  }

/* end CSS dynamic sidebar */


  .resume-page .sidebar-

  .item-sidebar-right ul {
    padding: 0;
  }
  #education {
    scroll-margin-top: -200px;
  }
  .banner-img, .img-wrapper {
    width:  100%;
    background-size:  cover;
    background-position:  center center;
  }
  .main-content h1 {
    margin-top: 33px;
  }
  :root {
    --base:              #f26c00;
    --bg-blend:          multiply;
    --blur:              0px;
    --fg-blend:          lighten;
    --fg-blend-dark:     overlay;
    --foreground:        #012e40;
    --opacity:           1;
  }
  .dark-mode .img-wrapper,  .sidebar-dark-mode .img-wrapper {
    background-color:    var(--base);
  }
  .dark-mode .img-wrapper img, .dark-mode .img-wrapper > div, .sidebar-dark-mode .img-wrapper img {
    filter:              grayscale(100%) contrast(1) blur(var(--blur));
    mix-blend-mode:      var(--bg-blend);
    object-fit:          cover;
    opacity:             var(--opacity);
  }
  .dark-mode .img-wrapper::before, .sidebar-dark-mode .img-wrapper::before {
    background-color:    var(--foreground);
    mix-blend-mode:      var(--fg-blend);
  }
  .profile-img {
    max-width:  100%;
  }
  .main-content .skills, .main-content .programming-languages, .main-content .tools {
    display: none;
  }
  .item-main-content {
    position:  relative;
  }
  .item-footer-content {
    width: 100%;
    bottom: 0;
  }
  html, body {
  height: 100%;
  }
 
  .content, .item-footer {
    flex-grow: 1;
  }
    html, body, .spiral-content {height:100%;}

  
  .item {
    display: grid;
    grid-template-columns: subgrid;
    grid-template-rows: subgrid;
  }
  .spi-one {
    grid-column: 1 / 22;
    grid-row: 1 / 18;
    background-color: slateblue;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    cursor:  pointer;
  }
  .spi-two {
    grid-column: 22 / 36;
    grid-row: 1 / 11;
    background-color: darkslateblue;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }

  .spi-three {
    grid-column: 27 / 36;
    grid-row: 11 / 18;
    background-color: mediumpurple;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }
  .spi-four {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    grid-column: 22 / 27;
    grid-row: 14 / 18;
    background-color: green;
  }
  .spi-five {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    grid-column: 22 / 25;
    grid-row: 11 / 14;
    background-color: purple;
  }
  .spi-six {
    grid-column: 25 / 27;
    grid-row: 11 / 14;
    background-color: rebeccapurple;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }
  .spiral-content > div {
        transition: .5s;
  }

}